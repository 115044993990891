import React, { Dispatch, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useAppDispatch } from '../../context/store';
import { CONFIRM_ADD_INCIDENT_REASON_MESSAGE, INCIDENT_SUB_TYPES, RP_BLUE } from '../../lib/constants';
import { showConfirmationDialog } from '../Commons';
import { addIncidentReasonAsync } from '../../slices/admin/incidentReasonsSlice';
type options = {
    value: string;
    label: string;
};
type Props = {
    isVisible: Dispatch<boolean>;
};

const INCIDENT_SUB_TYPE_SELECT_OPTIONS = [
    {
        label: INCIDENT_SUB_TYPES.EVACUATION,
        value: INCIDENT_SUB_TYPES.EVACUATION
    },
    {
        label: INCIDENT_SUB_TYPES.INVACUATION,
        value: INCIDENT_SUB_TYPES.INVACUATION
    }
];

const IncidentReason = ({ isVisible }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<options>(INCIDENT_SUB_TYPE_SELECT_OPTIONS[0]);
    const handleSubmit = async (): Promise<void> => {
        const confirmed = await showConfirmationDialog({
            title: CONFIRM_ADD_INCIDENT_REASON_MESSAGE
        });
        if (confirmed) {
            dispatch(
                addIncidentReasonAsync({
                    incidentSubTypeName: selectedOptions.value,
                    name,
                    description
                })
            );
            isVisible(false);
        }
    };

    const customSelectStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: '#667085',
            '&:hover': {
                borderColor: '#667085'
            },
            boxShadow: state.isFocused ? `0 0 0 1px ${RP_BLUE}` : 'none',
            fontSize: '14px'
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            fontSize: '14px'
        })
    };

    const handleEventReasonTypeChange = (values: options): void => {
        setSelectedOptions(values);
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header className='modalHeader' closeButton>
                <Modal.Title className='modalTitle'>Add Event Reasons</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    <Form.Group controlId='teamTypes'>
                        <Form.Label className='formLabel'>Event Type Category</Form.Label>
                        <Select
                            className='basic-single'
                            options={INCIDENT_SUB_TYPE_SELECT_OPTIONS}
                            placeholder={<Form.Text className='Placeholder'>Evacuation</Form.Text>}
                            onChange={handleEventReasonTypeChange}
                            components={{ IndicatorSeparator: () => null }}
                            value={selectedOptions}
                            styles={customSelectStyles}
                        />
                    </Form.Group>
                    <Form.Group controlId='inputCountry'>
                        <Form.Label className='formLabel'>
                            Add New Event Reason <span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                            className='formControl'
                            as='input'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            placeholder='Example: Fire'
                        />
                    </Form.Group>
                    <Form.Group controlId='teamId'>
                        <Form.Label className='formLabel'>
                            Add New Event Reason Description <span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                            rows={3}
                            style={{ borderColor: '#667085' }}
                            as='textarea'
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            placeholder={'Examples: fire, structural damage or gas leak'}
                        />
                    </Form.Group>
                    <Button
                        disabled={!selectedOptions || !name || !description}
                        className='my-2 saveButton'
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        className='mb-2 deleteCancelButton'
                        style={{ color: RP_BLUE }}
                        onClick={() => isVisible(false)}
                    >
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default IncidentReason;
