export const IncidentsHistoryTitlePropMap = {
    id: 'ID',
    country: 'Country',
    siteName: 'Sites',
    startTime: 'Start',
    endTime: 'End',
    incidentType: 'Event Type',
    incidentNewType: 'Event Classification',
    incidentSubType: 'Event Type',
    incidentReason: 'Event Reason',
    incidentCommander: 'Incident Commander',
    eventOwner: 'Event Owner',
    length: 'Length',
    onSite: 'On Site',
    numberAccounted: '# Accounted',
    percentAccounted: '% Accounted',
    totalVisitors: '# Visitors',
    countAddedToRoster: '# Added to Roster',
    report: 'Report'
};

export const IncidentsHistoryPropMap = {
    id: 'id',
    country: 'country',
    siteName: 'siteName',
    startTime: 'startTime',
    endTime: 'endTime',
    incidentType: 'incidentTypeName',
    incidentNewType: 'incidentNewTypeName',
    incidentSubType: 'incidentSubTypeName',
    incidentReason: 'incidentReasonName',
    incidentCommander: 'incidentCommander',
    eventOwner: 'eventOwner',
    length: 'duration',
    onSite: 'onSite',
    numberAccounted: 'numberAccounted',
    percentAccounted: 'percentAccounted',
    totalVisitors: 'totalVisitors',
    countAddedToRoster: 'countAddedToRoster',
    report: 'report'
};

export const IncidentsFilterPropMap: { [key: string]: string } = {
    id: 'id',
    siteName: 'siteName',
    startTime: 'startTime',
    endTime: 'endTime',
    incidentType: 'incidentTypeName',
    incidentNewType: 'incidentNewTypeName',
    incidentSubType: 'incidentSubTypeName',
    incidentReason: 'incidentReasonName',
    incidentCommander: 'incidentCommander',
    eventOwner: 'eventOwner',
    length: 'duration',
    onSite: 'totalOnsite',
    numberAccounted: 'totalAccountedFor',
    percentAccounted: 'pctAccountedFor'
};

export const IncidentsSortPropMap: { [key: string]: string } = {
    ...IncidentsFilterPropMap,
    siteName: 'sites.siteName'
};

export const RosterCSVTitlePropMap = {
    name: 'Name',
    login: 'Login',
    managerLogin: 'Manager Login',
    empId: 'Employee ID',
    lastBadgeTime: 'Last Badge Time',
    lastBadgePoint: 'Last Badge Point',
    site: 'Site',
    personnelType: 'Personnel Type',
    employeeManager: 'Manager',
    company: 'Company',
    entryDate: 'Entry Date',
    escortName: 'Escort Name',
    escortLogin: 'Escort Login',
    accountedFor: 'Accounted For',
    accountedBy: 'AccountedBy',
    lastAccountedTime: 'Last Accounted Time',
    accountedForType: 'Type',
    imtMembership: 'IMT'
};

export const RosterCSVPropMap = {
    name: 'name',
    login: 'login',
    managerLogin: 'managerLogin',
    empId: 'empId',
    lastBadgeTime: 'lastBadgeTime',
    lastBadgePoint: 'lastBadgePoint',
    site: 'site',
    personnelType: 'personnelType',
    employeeManager: 'employeeManager',
    company: 'company',
    entryDate: 'entryDate',
    escortName: 'escortName',
    escortLogin: 'escortLogin',
    accountedFor: 'accountedFor',
    accountedBy: 'accountedBy',
    lastAccountedTime: 'lastAccountedTime',
    accountedForType: 'accountedForType',
    imtMembership: 'imtMembership'
};
