import React, { useEffect } from 'react';
import '../../css/Admin.css';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import Container from 'react-bootstrap/Container';
import IncidentReasonsTable from '../../components/Tables/IncidentReasonsTable';
import { useAppDispatch } from '../../context/store';
import { getIncidentReasonsAsync } from '../../slices/admin/incidentReasonsSlice';
import { Button } from 'react-bootstrap';
import { useToggle } from 'react-use';
import IncidentReason from '../../components/Modals/IncidentReason';
import { INCIDENT_SUB_TYPES } from '../../lib/constants';

const styles = {
    addEditButton: {
        backgroundColor: '#EBECFF',
        width: '60px',
        height: '32px',
        border: 'none',
        color: '#0036C8',
        fontSize: '10pt',
        fontWeight: '500',
        borderRadius: '4px',
        float: 'right' as const,
        marginRight: '10px'
    }
};

export const IncidentReasons = (): JSX.Element => {
    const [openAddModal, setOpenAddModal] = useToggle(false);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getIncidentReasonsAsync());
    }, []);

    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <div style={{ maxWidth: '85%' }}>
                    <label className='dataAnonTableLabel'>Add Event Reasons</label>
                    <Button
                        title='Add'
                        size='sm'
                        type='Button'
                        onClick={() => {
                            setOpenAddModal(true);
                        }}
                        style={styles.addEditButton}
                    >
                        Add
                    </Button>
                </div>
                {openAddModal && <IncidentReason isVisible={setOpenAddModal} />}
                <IncidentReasonsTable incidentSubTypeName={INCIDENT_SUB_TYPES.EVACUATION} />
                <IncidentReasonsTable incidentSubTypeName={INCIDENT_SUB_TYPES.INVACUATION} />
            </div>
        </Container>
    );
};
