import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import {
    IncidentReasonsPropMap,
    IncidentReasonsTitlePropMap
} from '../../lib/dictionaries/IncidentReasonsDictionary';
import { NoDataIndication } from './NoDataIndication';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { INCIDENT_SUB_TYPE_IDS } from '../../lib/constants';

type Props = {
    incidentSubTypeName: string;
};

const IncidentReasonsTable = ({ incidentSubTypeName }: Props): JSX.Element => {
    const { incidentReasons, isGettingIncidentReasons } = useSelector(
        (state: RootState) => state.incidentReasons
    );
    const evacuationIncidentReasons = incidentReasons
        .filter(
            (data) =>
                data.incidentSubTypeId ===
                INCIDENT_SUB_TYPE_IDS[incidentSubTypeName.toUpperCase() as keyof typeof INCIDENT_SUB_TYPE_IDS]
        )
        .sort((a, b) => a.name.localeCompare(b.name));

    const reasonColumn = {
        dataField: IncidentReasonsPropMap.name,
        text: IncidentReasonsTitlePropMap.name,
        style: { width: '50%' }
    };

    const descriptionColumn = {
        dataField: IncidentReasonsPropMap.description,
        text: IncidentReasonsTitlePropMap.description,
        style: { width: '50%' }
    };

    const renderedColumns = [reasonColumn, descriptionColumn];

    return (
        <div id='Overrides' className='mt-4'>
            <label className='dataAnonTableLabel'>{incidentSubTypeName}</label>
            <ToolkitProvider
                keyField={IncidentReasonsPropMap.name}
                data={evacuationIncidentReasons}
                columns={renderedColumns}
            >
                {//eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props: any) => {
                    return (
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            bordered={false}
                            hover
                            wrapperClasses='table-responsive'
                            keyField={IncidentReasonsPropMap.name}
                            data={evacuationIncidentReasons}
                            columns={renderedColumns}
                            noDataIndication={NoDataIndication(isGettingIncidentReasons)}
                        />
                    );
                }}
            </ToolkitProvider>
        </div>
    );
};

export default IncidentReasonsTable;
