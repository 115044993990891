/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSVRow } from './helpers/CSVHelper';

export interface IncidentSite {
    incidentId: string;
    siteId: string;
    siteName: string;
    siteType: string;
    state?: string;
    city?: string;
    country?: string;
}

export interface Incident {
    id: string;
    incidentTypeId: number;
    incidentNewTypeId: number;
    incidentSubTypeId: number;
    incidentReasonId: number;
    sites: IncidentSite[];
    isOpen: boolean | number;
    startTime: string;
    endTime: string;
    participants: any[];
    incidentType: {
        id: number;
        name: string;
    };
    incidentNewType: {
        id: number;
        name: string;
    };
    incidentSubType: {
        id: number;
        name: string;
    };
    incidentReason: {
        id: number;
        incidentSubTypeId: number;
        name: string;
        description: string;
    };
    incidentCommander?: string;
    eventOwner?: string;
    totalBadgeHoldersAccountedFor: number;
    totalBadgeHolders: number;
    totalVisitorsAccountedFor: number;
    totalVisitors: number;
    totalContractors: number;
    totalContractorsAccountedFor: number;
    roster?: Roster[] | [];
    duration: string;
    totalOnsite: number;
    totalAccountedFor: number;
    pctAccountedFor: number;
    countAddedToRoster: number;
    isCorporate?: boolean;
}

export interface Roster {
    id: number;
    incidentId: string;
    empId: string;
    login: string;
    barcode: string;
    firstName: string;
    lastName: string;
    managerLogin: string;
    rosterEntryTypeId: number;
    lastCheckInMethodId: number | null;
    accountedFor: number | boolean;
    lastSeen: string;
    rosterEntryType: {
        id: number;
        name: string;
    } | null;
    checkInMethod: {
        id: number;
        name: string;
    } | null;
    escortId: string | null;
    escortLogin: string | null;
    isVisitor: number;
    visitorCompany: string;
    lastBadgePoint: string;
    accountedBy: any;
    lastBadgeTime: string;
    lastAccountedTime: string;
    imtMembership: string[];
}

export interface AuditTrail {
    id: number;
    time: string;
    user: string | null;
    role: string | null;
    action: string | null;
    message: string | null;
}

export interface Participant {
    id: number;
    incidentId: string;
    roleId: number;
    empId: string;
    login: string;
    firstName: string;
    lastName: string;
    role: {
        id: number;
        name: string;
    };
}

export interface IncidentHistory extends CSVRow {
    id: string;
    country: string | undefined;
    siteName: string;
    startTime: string;
    endTime: string;
    incidentTypeName: string;
    incidentNewTypeName: string;
    incidentSubTypeName: string;
    incidentReasonName: string;
    incidentCommander?: string | undefined;
    eventOwner?: string | undefined;
    duration: string;
    onSite: number;
    numberAccounted: number;
    percentAccounted: number;
    totalVisitors: number;
    countAddedToRoster: number;
}

export interface AnonymizationSettings {
    countryCode: string;
    days: number;
    dateLastModified: Date;
    modifiedBy: string;
}

export interface PhoneAnonymizationSettings {
    countryCode: string;
    incidentTypeNames: string[];
    incidentNewTypeNames: string[];
    dateLastModified: Date;
    modifiedBy: string;
}

export interface PolicyItem {
    subject: string;
    policyId: string;
    profileName: string;
    object: string;
    timestamp: number | string;
    subjectType: string;
    ownerType: string;
    policyType: string;
    action?: string;
}

export interface PermissionsContext {
    permissionsContext: {
        lastUpdated: number;
        policyItems?: any;
        initialized?: any;
        isFetchingPermissions?: any;
        policyManager?: any;
        time: number;
    };
}

export interface UserContext {
    userContext: {
        empId: string;
        login: string;
        email: string;
        firstName: string;
        lastName: string;
        jwtToken: string;
    };
}

export interface ComposedWithAuthAndPermissionsProps {
    userContext: {
        empId: string;
        login: string;
        email: string;
        firstName: string;
        lastName: string;
        jwtToken: string;
    };
    permissionsContext: {
        lastUpdated: number;
        policyItems?: any;
        initialized?: any;
        isFetchingPermissions?: any;
        policyManager?: any;
        time: number;
    };
}

export type IncidentType = 'Drill' | 'Shelter in Place' | 'Evacuation';
export type IncidentNewType = 'Real Emergency' | 'Drill' | 'Demo';
export type IncidentSubType =
    'Evacuation'
    | 'Invacuation'
    | 'Shelter in Place'; // deprecated, leaving in place for backwards compatibility

export interface UserPermissionRoles {
    webRole: 'Admin' | 'Business Team' | 'Event Owner' | 'User';
    mobileRole: 'Admin' | 'Event Owner' | 'User';
}

export const defaultUserPermissionRoles = (): UserPermissionRoles => {
    return {
        webRole: 'User',
        mobileRole: 'User'
    };
};

export interface UserPermissionActions {
    showOSR: boolean;
    showIncidentDetails: boolean;
    showIncidentHistory: boolean;
    showAdmin: boolean;
    testAccess: boolean;
}

export const defaultUserPermissionActions = (): UserPermissionActions => {
    return {
        showOSR: false,
        showIncidentDetails: false,
        showIncidentHistory: false,
        showAdmin: false,
        testAccess: false
    };
};

export interface UserCardholderInformation {
    empId: string;
    login: string;
    empStatus: string;
    lastName: string;
    firstName: string;
    empType: string;
    personId: string;
    barcode: string;
    manager: string;
    location: string;
    title: string;
    serviceDate: number;
    imgURL: string;
    badgeNumber: number;
    region: string | null;
    jobLevel: string | null;
    primaryEmail: string | null;
    primaryPhone: string | null;
    mobilePhone: string | null;
    homePhone: string | null;
    officePhone: string | null;
    maxActiveBadge: number;
    papiLastUpdatedTime: number;
    lastSyncTime: number;
    terminationDate: string | null;
    isManager: boolean;
}

export const emptyUserCardholderInformation = (): UserCardholderInformation => {
    return {
        empId: '',
        login: '',
        empStatus: '',
        lastName: '',
        firstName: '',
        empType: '',
        personId: '',
        barcode: '',
        manager: '',
        location: '',
        title: '',
        serviceDate: -1,
        imgURL: '',
        badgeNumber: -1,
        region: null,
        jobLevel: null,
        primaryEmail: null,
        primaryPhone: null,
        mobilePhone: null,
        homePhone: null,
        officePhone: null,
        maxActiveBadge: -1,
        papiLastUpdatedTime: -1,
        lastSyncTime: -1,
        terminationDate: null,
        isManager: false
    };
};

export interface UserRoleInformation {
    firstName: string;
    lastName: string;
    login: string;
    location: string;
    webRole: string;
    appRole: string;
    appVersion: string;
}

export interface ValidatedLogins {
    validLogins: string[] | [];
    invalidLogins: string[] | [];
    validCardholders: UserCardholderInformation[] | undefined;
}

export interface EventData {
    data: string;
    eventType: {
        id: number;
        name: string;
    };
    eventTypeId: number;
    id: number;
    incidentId: string;
    issuer: string | null;
    issuerTypeId: number | null;
    message: string | null;
    seuqenceId: number;
    timestamp: string;
    issuerType: {
        id: number;
        name: string;
    } | null;
}

export interface Device {
    platform: string;
    phoneNumber: string;
}

export interface RoleText {
    text: string;
    isAble: number;
}

export interface RoleInfo {
    tabs: RoleText[];
    features: RoleText[];
}

export interface RolePermissionsInformation {
    Admin: RoleInfo;
    Business: RoleInfo;
    EventOwner: RoleInfo;
    User: RoleInfo;
    version: string;
}

export interface PermissionsTeamsEntries {
    id: number;
    name: string;
    extId: string;
    roleType: string;
    updatedBy: string;
    lastUpdated: Date;
}

export interface FacManKeysEntries {
    id: string;
    name: string;
    updatedBy: string;
    lastUpdated: Date;
}

export interface RosterFilterAttributes {
    [name: string]: string[] | number[] | boolean[] | { [name: string]: string[] };
}

export interface IncidentHistoryMultiFilterAttributes {
    [name: string]: string[] | number[] | boolean[];
}

export interface PopoverStyle {
    backgroundColor: string;
    color: string;
}
