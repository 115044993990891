export const IncidentReasonsTitlePropMap = {
    id: 'ID',
    incidentSubTypeId: 'Incident Subtype ID',
    name: 'Event Reasons',
    description: 'Event Reason Description'
};

export const IncidentReasonsPropMap = {
    id: 'id',
    incidentSubTypeId: 'incidentSubTypeId',
    name: 'name',
    description: 'description'
};

export const IncidentReasonsFilterPropMap: { [key: string]: string } = {
    id: 'id',
    incidentSubTypeId: 'incidentSubTypeId',
    name: 'name',
    description: 'description'
};

export const IncidentReasonsSortPropMap: { [key: string]: string } = {
    ...IncidentReasonsFilterPropMap
};
