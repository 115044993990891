export const COGNITO_REDIRECT_TO = process.env.REACT_APP_COGNITO_REDIRECT_TO;

export const COGNITO_APP_WEB_DOMAIN = process.env.REACT_APP_COGNITO_APP_WEB_DOMAIN;

export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

export const COGNITO_USERPOOL = process.env.REACT_APP_COGNITO_USERPOOL;

export const API_GATEWAY_API_KEY = process.env.REACT_APP_API_GATEWAY_API_KEY;

export const STAGE = process.env.REACT_APP_STAGE;

export const ADD_LEADER_API_ENDPOINT = process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addLeader';

export const SEARCH_INCIDENTS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/searchIncidents';

export const SEARCH_ROSTER_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/searchRoster';

export const SEARCH_INCIDENT_EVENTS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getEvents';

export const DOWNLOAD_MOBILE_APP_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/download';

export const GET_ANONYMIZATION_SETTINGS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getAnonymizationSettings';

export const ADD_ANONYMIZATION_SETTING_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addAnonymizationSetting';

export const UPDATE_ANONYMIZATION_SETTING_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/updateAnonymizationSetting';

export const DELETE_ANONYMIZATION_SETTING_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/deleteAnonymizationSetting';

export const ADD_POLICY_API_ENDPOINT =
    process.env.REACT_APP_USER_PROFILE_SERVICE_ENDPOINT + '/rallypoint/addPolicy';

export const REMOVE_POLICIES_API_ENDPOINT =
    process.env.REACT_APP_USER_PROFILE_SERVICE_ENDPOINT + '/rallypoint/removePolicies';

export const GET_ROLE_PERMISSIONS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getRolePermissions';

export const GET_PHONE_ANONYMIZATION_SETTINGS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getPhoneAnonymizationSettings';

export const ADD_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addPhoneAnonymizationSettings';

export const UPDATE_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/updatePhoneAnonymizationSettings';

export const DELETE_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/deletePhoneAnonymizationSettings';

export const GET_PERMISSIONS_TEAMS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getPermissionsTeams';

export const ADD_PERMISSIONS_TEAM_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addPermissionsTeam';

export const REMOVE_PERMISSIONS_TEAM_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/removePermissionsTeam';

export const GET_FACMAN_KEYS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getResolverKeys';

export const ADD_FACMAN_KEY_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addResolverKey';

export const REMOVE_FACMAN_KEY_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/removeResolverKey';

export const GET_GSOC_PHONE_NUMBERS_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getGsocPhoneNumbers';

export const ADD_GSOC_PHONE_NUMBER_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addGsocPhoneNumber';

export const UPDATE_GSOC_PHONE_NUMBER_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/updateGsocPhoneNumber';

export const REMOVE_GSOC_PHONE_NUMBER_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/removeGsocPhoneNumber';

export const GET_INCIDENT_REASONS_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getIncidentReasons';

export const ADD_INCIDENT_REASON_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/addIncidentReason';

export const UPDATE_INCIDENT_REASON_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/updateIncidentReason';

export const REMOVE_INCIDENT_REASON_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/removeIncidentReason';

export const GET_CARDHOLDERS_API_ENDPOINT =
    process.env.REACT_APP_GUARDIAN_CARDHOLDER_MANAGEMENT_ENDPOINT + '/getCardholders';

export const SEARCH_CARDHOLDERS_API_ENDPOINT =
    process.env.REACT_APP_GUARDIAN_CARDHOLDER_MANAGEMENT_ENDPOINT + '/searchCardholders';

export const SEARCH_LOGINS_API_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/searchLogins';

export const GET_ROLE_BY_LOGINS_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/getRoleByLogins';

export const GET_LATEST_APP_VERSION_ENDPOINT =
    process.env.REACT_APP_RALLY_POINT_SERVICE_ENDPOINT + '/appUpdateCheck';

export const VALIDATE_GROUP_ID_API_ENDPOINT =
    process.env.REACT_APP_GUARDIAN_TEAMS_SERVICE_ENDPOINT + '/rallypoint/validateTeams';

export const GET_FACILITY_LIST_ENDPOINT =
    process.env.REACT_APP_LOCATOR_SERVICE_ENDPOINT + '/getFacilityNames';

export const GET_ONSITE_REPONDERS_ENDPOINT =
    process.env.REACT_APP_LOCATOR_SERVICE_ENDPOINT + '/getOnsiteResponders';

export const INCIDENT_DETAILS_LINK_PREFIX = COGNITO_REDIRECT_TO + '/eventDetails/';

export const SORT_ORDER_DIRECTIONS = {
    ASCENDING: 'asc',
    DESCENDING: 'desc'
};

export const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;

// API Gateway Endpoint for Katal Logger
export const KATAL_LOGGER_ENDPOINT = process.env.REACT_APP_KATAL_LOGGER_ENDPOINT;
