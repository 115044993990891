import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { AppThunk } from '../../context/store';
import { toast } from 'react-toastify';
import {
    GET_INCIDENT_REASONS_ENDPOINT,
    ADD_INCIDENT_REASON_ENDPOINT,
    UPDATE_INCIDENT_REASON_ENDPOINT,
    REMOVE_INCIDENT_REASON_ENDPOINT
} from '../../lib/globals';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';

const { doRequest } = asyncRequest;

interface IncidentReason {
    id: number;
    incidentSubTypeId: number;
    name: string;
    description: string;
}

interface AddIncidentReasonRequest {
    incidentSubTypeId?: number;
    incidentSubTypeName?: string;
    name: string;
    description: string;
}

interface UpdateIncidentReasonRequest {
    id: number;
    incidentSubTypeId: number;
    name: string;
    description: string;
}

interface RemoveIncidentReasonRequest {
    id: number;
}

interface IncidentReasonsState {
    incidentReasons: IncidentReason[];
    isGettingIncidentReasons: boolean;
    isAddingIncidentReason: boolean;
    isUpdatingIncidentReason: boolean;
    isRemovingIncidentReason: boolean;
    getIncidentReasonsError: string;
    addIncidentReasonError: string;
    updateIncidentReasonError: string;
    removeIncidentReasonError: string;
}

export const initialState: IncidentReasonsState = {
    incidentReasons: [],
    isGettingIncidentReasons: false,
    isAddingIncidentReason: false,
    isUpdatingIncidentReason: false,
    isRemovingIncidentReason: false,
    getIncidentReasonsError: '',
    addIncidentReasonError: '',
    updateIncidentReasonError: '',
    removeIncidentReasonError: ''
};

const incidentReasonsSlice = createSlice({
    name: 'incidentReasons',
    initialState,
    reducers: {
        startGetIncidentReasons: (state) => {
            state.incidentReasons = initialState.incidentReasons;
            state.isGettingIncidentReasons = true;
            state.getIncidentReasonsError = initialState.getIncidentReasonsError;
        },
        startAddIncidentReason: (state) => {
            state.isAddingIncidentReason = true;
            state.addIncidentReasonError = initialState.addIncidentReasonError;
        },
        startUpdateIncidentReason: (state) => {
            state.isUpdatingIncidentReason = true;
            state.updateIncidentReasonError = initialState.updateIncidentReasonError;
        },
        startRemoveIncidentReason: (state) => {
            state.isRemovingIncidentReason = true;
            state.removeIncidentReasonError = initialState.removeIncidentReasonError;
        },
        getIncidentReasonsSucceeded: (
            state,
            { payload }: PayloadAction<{ incidentReasons: IncidentReason[] }>
        ) => {
            const { incidentReasons } = payload;
            state.isGettingIncidentReasons = false;
            state.incidentReasons = incidentReasons;
        },
        addIncidentReasonSucceeded: (state) => {
            state.isAddingIncidentReason = false;
        },
        updateIncidentReasonSucceeded: (state) => {
            state.isUpdatingIncidentReason = false;
        },
        removeIncidentReasonSucceeded: (state) => {
            state.isRemovingIncidentReason = false;
        },
        getIncidentReasonsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingIncidentReasons = false;
            state.getIncidentReasonsError = error;
        },
        addIncidentReasonFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isAddingIncidentReason = false;
            state.addIncidentReasonError = error;
        },
        updateIncidentReasonFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isUpdatingIncidentReason = false;
            state.updateIncidentReasonError = error;
        },
        removeIncidentReasonFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isRemovingIncidentReason = false;
            state.removeIncidentReasonError = error;
        }
    }
});

export const {
    startGetIncidentReasons,
    startAddIncidentReason,
    startUpdateIncidentReason,
    startRemoveIncidentReason,
    getIncidentReasonsSucceeded,
    addIncidentReasonSucceeded,
    updateIncidentReasonSucceeded,
    removeIncidentReasonSucceeded,
    getIncidentReasonsFailed,
    addIncidentReasonFailed,
    updateIncidentReasonFailed,
    removeIncidentReasonFailed
} = incidentReasonsSlice.actions;

export default incidentReasonsSlice.reducer;

export const getIncidentReasonsAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetIncidentReasons());
        const response = await doRequest(
            GET_INCIDENT_REASONS_ENDPOINT,
            {},
            GuardianAuth.createRequestAuthHeader()
        );
        const incidentReasonsResult = response?.data;
        dispatch(getIncidentReasonsSucceeded({ incidentReasons: incidentReasonsResult }));
    } catch (error) {
        dispatch(getIncidentReasonsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={'Failed to retrieve list of event reasons'}
                errorMessage={error.message}
            />
        );
    }
};

export const addIncidentReasonAsync = (request: AddIncidentReasonRequest): AppThunk => async (dispatch) => {
    const toastId = toast.info('Adding new event reason...');
    try {
        dispatch(startAddIncidentReason());
        await doRequest(ADD_INCIDENT_REASON_ENDPOINT, request, GuardianAuth.createRequestAuthHeader());
        dispatch(addIncidentReasonSucceeded());
        toast.update(toastId, {
            render: 'Successfully added new event reason',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getIncidentReasonsAsync());
    } catch (error) {
        dispatch(addIncidentReasonFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage header={'Failed to add new event reason'} errorMessage={error.message} />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const updateIncidentReasonAsync = (request: UpdateIncidentReasonRequest): AppThunk => async (
    dispatch
) => {
    const toastId = toast.info('Updating existing event reason...');
    try {
        dispatch(startUpdateIncidentReason());
        await doRequest(UPDATE_INCIDENT_REASON_ENDPOINT, request, GuardianAuth.createRequestAuthHeader());
        dispatch(updateIncidentReasonSucceeded());
        toast.update(toastId, {
            render: 'Successfully updated existing event reason',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getIncidentReasonsAsync());
    } catch (error) {
        dispatch(updateIncidentReasonFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Failed to update existing event reason'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const removeIncidentReasonAsync = (request: RemoveIncidentReasonRequest): AppThunk => async (
    dispatch
) => {
    const toastId = toast.info('Removing existing event reason...');
    try {
        dispatch(startRemoveIncidentReason());
        await doRequest(REMOVE_INCIDENT_REASON_ENDPOINT, request, GuardianAuth.createRequestAuthHeader());
        dispatch(removeIncidentReasonSucceeded());
        toast.update(toastId, {
            render: 'Successfully removed existing event reason',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getIncidentReasonsAsync());
    } catch (error) {
        dispatch(removeIncidentReasonFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Failed to remove existing event reason'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};
